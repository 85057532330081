const _temp0 = require("./area.png");
const _temp1 = require("./capital.png");
const _temp2 = require("./car_side.png");
const _temp3 = require("./currency.png");
const _temp4 = require("./languages.png");
const _temp5 = require("./leaflet.png");
const _temp6 = require("./population.png");
const _temp7 = require("./region.png");
const _temp8 = require("./rest_countries_api.png");
const _temp9 = require("./timezones.png");
const _temp10 = require("./unsplash.png");
const _temp11 = require("./wikipedia.png");
module.exports = {
  "area": _temp0,
  "capital": _temp1,
  "car_side": _temp2,
  "currency": _temp3,
  "languages": _temp4,
  "leaflet": _temp5,
  "population": _temp6,
  "region": _temp7,
  "rest_countries_api": _temp8,
  "timezones": _temp9,
  "unsplash": _temp10,
  "wikipedia": _temp11
}